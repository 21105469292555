import React from 'react';
import { Box } from '@mui/material';
import { createIntl, createIntlCache } from 'react-intl';
import AppLocale from 'i18n';

const cache = createIntlCache();
const intl = createIntl(AppLocale.ko, cache);

const Footer = (props) => {
  const date = new Date();

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" {...props}>
      <Box display="flex" alignItems="center">
        <span style={{ fontSize: 14, color: '#666666' }}>
          {intl.formatMessage({ id: 'footer.copyright' }, { date: date.getFullYear() })}
        </span>
      </Box>
    </Box>
  );
};

export default Footer;
