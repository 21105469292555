import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { JWTAuth } from 'services';
import { LOCALSTORAGE_KEY_autoLogin, SESSIONSTORAGE_KEY_loginState } from '@bis/constants/constStrings';
import CircularProgress from '@mui/material/CircularProgress';
import VerticalMinimal from './VerticalLayouts/VerticalMinimal';
import useClasses from 'hook/useClasses';
import CmtDialog from '@coremat/CmtDialog';
import { CFN_isAdmin } from 'utils/commFunction';

const styles = {
  circularProgressRoot: {
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const AppLayout = ({ children }) => {
  const [showLayoutLoader, setLayoutLoader] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  const { loadUser } = useSelector(({ auth }) => auth);
  const roleName = useSelector((state) => state.auth.authUser?.roleName);
  const storeCode = useSelector((state) => state.common.selectedStoreCode);
  const storeList = useSelector((state) => (state.auth.authUser ? state.auth.authUser.storeList : []));
  const { selectedStoreCode, selectedStoreName } = useSelector((state) => state.common);

  const dispatch = useDispatch();
  const classes = useClasses(styles);
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const position = pathname.lastIndexOf('/');
  const url = location.pathname.slice(0, position);
  const subscriptionPage = ['raw-material-status-forecast', 'usp-shipping'].some((path) => location.pathname.includes(path));
  const subscription = storeList.find((el) => el.code === selectedStoreCode)?.subscription;

  useEffect(() => {
    if (storeCode !== 'ALL') {
      if (subscription) {
        const { is_experience, product } = subscription;
        const subscriptionService = product === 'PREMIUM' || is_experience;

        if (!CFN_isAdmin(roleName) && !subscriptionService && subscriptionPage) {
          setIsOpen(true);
          navigate(`${url}/ALL`);
        }
      }
    } else {
      setIsOpen(false);
    }
  }, [roleName, subscriptionPage, subscription]);

  useEffect(() => {
    const isAutoLogin = localStorage.getItem(LOCALSTORAGE_KEY_autoLogin);
    const isLogining = sessionStorage.getItem(SESSIONSTORAGE_KEY_loginState);

    if (isAutoLogin === 'true') {
      // 자동 로그인을 체크 하고 로그인을 했다면 바로 auth로 이동 시킨다.
      dispatch(JWTAuth.getAuthUser());
    } else {
      // 자동 로그인이 되어 있지 않고,
      if (isLogining === 'true') {
        // 현재 로그인 상태라면 바로 auth로 이동 시킨다.
        dispatch(JWTAuth.getAuthUser());
      } else {
        // 현재 로그인 상태가 아니라면 로그 아웃 시킨다.
        dispatch(JWTAuth.onLogout());
      }
    }
    setLayoutLoader(false);

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (showLayoutLoader || !loadUser) {
    return (
      <div className={classes.circularProgressRoot}>
        <CircularProgress />
      </div>
    );
  }

  if (['/signin', '/signup', '/forgot-password'].includes(location.pathname)) {
    return <div style={{ minHeight: '100vh', width: '100%', display: 'flex' }}>{children}</div>;
  }

  //패스워드 변경/등록
  if (
    location.pathname.substring(0, 5) === '/init' || // 비밀번호 초기 등록 페이지, /init/:token, 문자열 앞을 잘라서 비교
    location.pathname.substring(0, 7) === '/change' // 비밀번호 재 등록 페이지, /change/:token, 문자열 앞을 잘라서 비교
  ) {
    return <div style={{ minHeight: '100vh', width: '100%', display: 'flex' }}>{children}</div>;
  }

  return (
    <>
      <CmtDialog cancle={false} type="confirm" open={isOpen} setOpen={setIsOpen}>
        <div style={{ padding: '0px 34px 0px', textAlign: 'center' }}>
          <p>{selectedStoreName} 매장에</p>
          <p>접근 권한이 없는 페이지 입니다.</p>
          <p>구독 서비스 ‘기본’ 페이지로 이동 후 변경해 주세요.</p>
        </div>
      </CmtDialog>
      <VerticalMinimal children={children} />
    </>
  );
};

export default AppLayout;
