/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import {
  STORECODE_ALL_BEATBOX,
  STORECODE_ALL_BEAT,
  STORECODE_ALL,
  STR_initstore,
  STR_Zone,
} from '@bis/constants/constStrings';
import { ENUM_STORE_TYPE, ENUM_TITLE_UNIT } from '@bis/constants/enums';
import { createIntl, createIntlCache } from 'react-intl';
import { Tooltip } from '@mui/material';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { COLOR_DOWN, COLOR_STRING, COLOR_UP } from '../@bis/constants/Color';
import { isDate1InThisWeek, isPast, timeLabelNameA } from './dateHelper';
import { cycle } from 'routes/Pages/Bis/RealTimeSales/Cards/common';
import AppLocale from 'i18n';
import theme from 'theme/theme';
import dayjs from 'dayjs';
import { isNull } from 'lodash';

const cache = createIntlCache();
const intl = createIntl(AppLocale.ko, cache);

/**
 * Zone- 이 포함 된 내용을 찾는다.
 * @param {*} zone
 * @returns
 */
export const CFN_findZone = (zone) => zone.name.startsWith(STR_Zone);

/**
 *
 * @param {number} value
 * @param {*} locale
 * @returns
 */
// 앞에 0으로 패딩 하고 싶다면 padStart()를 사용 하면 된다.
// ex) export const toCupString = (value) => {return `${value.toLocaleString('kr-KR').padStart(3, '0')}잔`};
export const CFN_addComma = (value, none = '-', locale = 'kr-KR') => {
  if (typeof value !== 'number') return none;
  return value.toLocaleString(locale);
};

/**
 * ALL, ALL_BEAT, ALL_BEATBOX 포함
 * @param {string} storeCode string
 * @returns boolean
 */
export const CFN_isIncludeAllStore = (storeCode) => {
  if (storeCode === STORECODE_ALL || storeCode === STORECODE_ALL_BEAT || storeCode === STORECODE_ALL_BEATBOX) {
    return true;
  }
  return false;
};

/**
 * ALL_BEAT, ALL_BEATBOX만 포함
 * @param {string} storeCode string
 * @returns boolean
 */
export const CFN_isIncludeAllBeatAllBeatbox = (storeCode) => {
  if (storeCode === STORECODE_ALL_BEAT || storeCode === STORECODE_ALL_BEATBOX) {
    return true;
  }
  return false;
};

/**
 *
 * @param {string} storeCode string
 * @param {string} floorId string
 * @returns ENUMS
 */
export const CFN_enumStoreType = (storeCode, floorId = null) => {
  if (storeCode === STORECODE_ALL) {
    return ENUM_STORE_TYPE.ALL_STORE;
  } else if (storeCode === STORECODE_ALL_BEAT) {
    return ENUM_STORE_TYPE.ALL_BEAT;
  } else if (storeCode === STORECODE_ALL_BEATBOX) {
    return ENUM_STORE_TYPE.ALL_BEATBOX;
  } else if (storeCode && floorId !== null) {
    return ENUM_STORE_TYPE.BEATBOX;
  } else if (storeCode && floorId === null) {
    return ENUM_STORE_TYPE.BEAT;
  } else {
    return ENUM_STORE_TYPE.UNKNOWN;
  }
};

/**
 * 어드민 여부
 * @param {string} roleName string
 * @returns boolean
 */
export const CFN_isAdmin = (roleName) => {
  return roleName === 'ADMIN' ? true : false;
};

/**
 * 초기 주소 여부
 * @param {string} value string
 * @returns boolean
 */
export const CFN_isInitstore = (value) => {
  return value === STR_initstore;
};

/**
 *
 * @param {number} value 값
 * @param {*} unit 단위
 * @param {boolean} iconOff 아이콘 표시 여부
 * @param {boolean} disabled 조회불가시 true, 조회가능시 false
 * @returns
 */
export const CFN_pickSignAndColor = (value, unit, iconOff = false, disabled = false) => {
  let valueWithSign = intl.formatMessage({ id: 'unit.disableSearch' });

  let colorByValue = 'rgba(0,0,0,0.6)';
  let weightByValueType = 'normal';
  let tranding = null;

  valueWithSign = `${value}${unit}`;

  if (disabled) {
    valueWithSign = intl.formatMessage({ id: 'unit.disableSearch' });
    colorByValue = 'rgba(0,0,0,0.6)';
    return { valueWithSign, colorByValue, weightByValueType, tranding };
  }

  if (unit !== '%') {
    colorByValue = !iconOff ? COLOR_STRING : 'rgba(0,0,0,0.6)';
    return { valueWithSign, colorByValue, weightByValueType, tranding };
  }

  if (isNaN(value) || value === 0) {
    colorByValue = !iconOff ? '#000000' : 'rgba(0,0,0,0.6)';
  }

  if (value < 0) {
    colorByValue = !iconOff ? COLOR_DOWN : 'rgba(0,0,0,0.6)';
    tranding = !iconOff ? <TrendingDownIcon /> : null;
  } else if (value > 0) {
    colorByValue = !iconOff ? COLOR_UP : 'rgba(0,0,0,0.6)';
    tranding = !iconOff ? <TrendingUpIcon /> : null;
  }

  valueWithSign = `${Math.abs(value)}${unit}`;
  weightByValueType = 'bold';
  return { valueWithSign, colorByValue, weightByValueType, tranding };
};

/**
 * 타이틀에 따른 단위 포함
 * @param {number} value
 * @param {number} titleUnit
 * @returns
 */
export const CFN_titleValueWithUnit = (value, titleUnit = ENUM_TITLE_UNIT.UNKNOWN) => {
  let conv_Value = '';
  if (typeof value === 'number') {
    conv_Value = `${CFN_addComma(value)}`;
  } else {
    conv_Value = `${value}`;
  }

  let unit = '';
  switch (titleUnit) {
    case ENUM_TITLE_UNIT.HUMAN:
      unit = intl.formatMessage({ id: 'unit.human' });
      break;
    case ENUM_TITLE_UNIT.CURRENCY:
      unit = intl.formatMessage({ id: 'unit.currency' });
      break;
    case ENUM_TITLE_UNIT.CUPCOUNT:
      unit = intl.formatMessage({ id: 'unit.cupCount' });
      break;
    case ENUM_TITLE_UNIT.PERCENT:
      unit = '%';
      break;
    case ENUM_TITLE_UNIT.CUPANDPERSON:
      unit = intl.formatMessage({ id: 'unit.cupPerPerson' });
      break;
    default:
      return value;
  }

  return (
    <>
      {conv_Value}
      {unit}
    </>
  );
};

/**
 * help 아이콘 표시 및 내용 표기
 * @param {string} help
 * @returns
 */
export const CFN_HelpIcon = (help) => {
  return (
    <Tooltip
      title={<span style={{ whiteSpace: 'pre-line' }}>{help}</span>}
      placement="top-end"
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            padding: '16px',
            bgcolor: '#a1aabb',
            borderRadius: '4px',
            fontSize: 14,
            fontWeight: 'normal',
            fontFamily: 'NanumSquareOTF',
            '& .MuiTooltip-arrow': {
              fontSize: 16,
              color: '#a1aabb',
            },
          },
        },
      }}>
      <HelpOutlineIcon style={{ color: '#666', fontSize: 18 }} />
    </Tooltip>
  );
};

/**
 * tooltip 및 내용 표기
 * @param {string} str
 * @param {*} children
 * @returns
 */
export const CFNTooltip = ({ str, children }) => {
  return (
    <Tooltip
      title={<span style={{ whiteSpace: 'pre-line' }}>{str}</span>}
      placement="top-end"
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            padding: '16px',
            bgcolor: '#a1aabb',
            borderRadius: '4px',
            fontSize: 14,
            fontWeight: 'normal',
            fontFamily: 'NanumSquareOTF',
            '& .MuiTooltip-arrow': {
              fontSize: 16,
              color: '#a1aabb',
            },
          },
        },
      }}>
      {children}
    </Tooltip>
  );
};

/**
 * value 값에 따른 색 값
 * @param {number} value
 * @returns
 */
export const CFN_numberSignAndColor = (value) => {
  let valueWithSign;
  let colorByValue = 'rgba(0,0,0,0.6)';
  let sign = '';

  if (isNaN(value) || value === 0) {
    valueWithSign = ``;
    colorByValue = '#000000';
    return { valueWithSign, colorByValue };
  }

  if (value < 0) {
    colorByValue = COLOR_DOWN;
    sign = '-';
  } else if (value > 0) {
    colorByValue = COLOR_UP;
    sign = '+';
  }

  valueWithSign = `${sign}${CFN_addComma(Math.abs(value))}`;
  return { valueWithSign, colorByValue };
};

/**
 * contrast 값을 따른 색 값과 value 절대값 리턴
 * @param {number} param
 * @returns value : 절대값, color : 색상값
 */
export const CFN_ABSNumberAndColor = (param) => {
  let value = '';
  let color = 'rgba(0,0,0,0.6)';

  if (isNaN(param) || param === 0) {
    value = `0`;
    return { value, color };
  }

  if (param < 0) {
    color = COLOR_DOWN;
  } else if (param > 0) {
    color = COLOR_UP;
  }

  value = `${CFN_addComma(Math.abs(param))}`;
  return { value, color };
};

/**
 * number가 0보다 크거나 작을때의 색상값
 * @param {number} param
 * @returns color string
 */
export const CFN_IncreaseColor = (param) => {
  let color = theme.color.darkGray;

  if (isNaN(param) || param === 0) {
    return color;
  }

  if (param < 0) {
    color = COLOR_DOWN;
  } else if (param > 0) {
    color = COLOR_UP;
  }

  return color;
};

/**
 * 증감율 아이콘
 * @param {number} value
 * @param {number} fontsize
 * @returns
 */
export const CFN_TrendingIcon = (value, fontsize = 18) => {
  let tranding = null;
  if (value < 0) {
    tranding = <TrendingDownIcon style={{ color: COLOR_DOWN, fontSize: fontsize }} />;
  } else if (value > 0) {
    tranding = <TrendingUpIcon style={{ color: COLOR_UP, fontSize: fontsize }} />;
  }

  return tranding;
};

/**
 * 전제 매장 또는 전체 비트박스 매장
 * @param {string} storeCode
 * @param {string} floorId
 * @returns
 */
export const CFN_isAllorAllBeatBox = (storeCode, floorId = null) => {
  const storeType = CFN_enumStoreType(storeCode, floorId);
  return storeType === ENUM_STORE_TYPE.ALL_STORE || storeType === ENUM_STORE_TYPE.ALL_BEATBOX;
};

/**
 * 전체 비트 또는 비트
 * @param {string} storeCode
 * @param {string} floorId
 * @returns
 */
export const CFN_isAllBeatorBeat = (storeCode, floorId = null) => {
  const storeType = CFN_enumStoreType(storeCode, floorId);
  return storeType === ENUM_STORE_TYPE.ALL_BEAT || storeType === ENUM_STORE_TYPE.BEAT;
};

/**
 * lidar가 적용된 매장 인 경우
 * @param {string} storeCode
 * @param {string} floorId
 * @returns
 */
export const CFN_useLidar = (storeCode, floorId = null) => {
  const storeType = CFN_enumStoreType(storeCode, floorId);
  return (
    storeType === ENUM_STORE_TYPE.ALL_BEATBOX ||
    storeType === ENUM_STORE_TYPE.BEATBOX ||
    storeType === ENUM_STORE_TYPE.ALL_STORE
  );
};

/**
 * 비트박스 (라이다 o) 인가
 * @param {string} storeCode
 * @param {string} floorId
 * @returns
 */
export const CFN_isBeatBox = (storeCode, floorId = null) => {
  const storeType = CFN_enumStoreType(storeCode, floorId);
  return storeType === ENUM_STORE_TYPE.BEATBOX;
};

/**
 * 분, 초 카운트다운에 대한 퍼센테이지 값 리턴
 * @param {number} value  카운트 다운 값
 * @param {number} max 최대 기대 값
 * @param {number} min 최소 기대 값
 * @returns
 */

export const CFN_PageTopProgresssValue = (value, max = 60, min = 0) => {
  if (max === 0) return 0;
  if (max - min === 0) return 0;

  return ((max - value - min) * 100) / (max - min);
};

/**
 * 날짜의 요일을 얻는다.
 * @param {date} date
 * @returns
 */
export const CFN_getDayOfWeek = (date) => {
  const week = ['일', '월', '화', '수', '목', '금', '토'];

  const dayOfWeek = week[new Date(date).getDay()];
  return dayOfWeek;
};

/**
 * 날짜의 주말 여부를 확인
 * @param {date} date
 * @returns 토요일, 일요일 이면 true, 이외 요일은 false
 */
export const CFN_checkWeekend = (date) => {
  const weekday = new Date(date).getDay();

  if (weekday === 0 || weekday === 6) return true;
  return false;
};

/**
 * 날짜의 요일을 얻는다.
 * @param {number} index
 * @returns
 */
export const CFN_geyDayOfWeekByIndex = (index) => {
  const week = ['일', '월', '화', '수', '목', '금', '토'];

  if (index > week.length) return 'err';

  return week[index];
};

/**
 * 소수 2자리까지 표현 한 %
 * 입력값이 0 또는 100일 경우는 소주 자리는 표현 하지 않는다.
 * @param {*} value
 * @returns
 */
export const CFN_contrastFixed = (value) => {
  if (typeof value === 'number') {
    let point = 2;
    if (value === 100 || value === 0) {
      point = 0;
    }
    return `${Math.abs(value).toFixed(point)}%`;
  } else {
    return value;
  }
};

/**
 * 소수 2자리까지 표현
 * 입력값이 0 또는 100일 경우는 소주 자리는 표현 하지 않는다.
 * @param {*} value
 * @returns
 */
export const CFN_valueFixed = (value, point = 2) => {
  if (typeof value === 'number') {
    if (value === 100 || value === 0) {
      point = 0;
    }
    return value.toFixed(point);
  } else {
    return value;
  }
};

/**
 * 대비 값을 토글 스위치에 따라 문자열로 나타낸다.
 * @param {number} value
 * @param {string} unit
 * @param {boolean} isContrast
 * @returns
 */
export const CFN_contrastValue = (value, unit, isContrast = false) => {
  if (typeof value !== 'number') {
    if (value === 'NaN' || value === '-') return '-';
    return '';
  }

  const sinPlus = (value) => {
    if (value > 0) return '+';
    if (value < 0) return '-';
    return '';
  };

  return isContrast
    ? `${sinPlus(value)} ${CFN_contrastFixed(value)}`
    : `${sinPlus(value)} ${CFN_addComma(Math.abs(value))}${unit}`;
};

/**
 * 값이 소수점일 경우 몇자리까지 표현할 것인지 계산
 * @param {number} value
 * @param {number} point
 * @returns
 */
export const CFN_TOFIXED = (value, point = 2) => {
  if (typeof value !== 'number') {
    if (value === 'NaN' || value === '-') return '-';
    return '';
  }

  if (value === 100 || value === 0) {
    point = 0;
  }

  return parseFloat(value.toFixed(point));
};

/**
 * 카테고리 비율에서 사용될 이름 반환
 * @param {string} type 카테고리 타입
 * @returns
 */
export const CFN_CategoryConvertName = (type) => {
  if (type === 'C') return 'Coffee';
  else if (type === 'NC') return 'Non Coffee';
  else if (type === 'H') return 'HOT';
  else if (type === 'I') return 'ICE';

  return 'UNKNOWN';
};

/**
 * 데이터 결과를 모아 새로운 배열을 반환
 * @param {string} list
 * @param {string} dateLabel
 * @param {string} valueFormat
 * @returns
 */
export const CFN_DataListMap = (list, dateLabel, valueFormat, valueKey) => {
  return list.map((item) => {
    return {
      ...item,
      newDate: dateLabel(item.dateTime),
      value: valueFormat(item[valueKey]),
    };
  });
};

/**
 * @param {string} date
 * @returns
 */
export function oneDayLabel(date) {
  return timeLabelNameA(date, 'HH시mm분');
}

/**
 * @param {string} date
 * @returns
 */
export function startEndDateLabel(date) {
  const start = timeLabelNameA(date, 'HH시mm분');
  const end = timeLabelNameA(dayjs(date).add(cycle - 1, 'minutes'), 'HH시mm분');
  return `${start} ~ ${end}`;
}

/**
 * @param {number} value
 * @returns
 */
export function percentValue(value) {
  return `${CFN_addComma(value * 100)}${intl.formatMessage({ id: 'unit.percent' })}`;
}

/**
 * @param {string} value
 * @returns
 */
export function salesValue(value) {
  return `${CFN_addComma(value)}${intl.formatMessage({ id: 'unit.currency' })}`;
}

/**
 * @param {string} value
 * @returns
 */
export function salesCups(value) {
  return `${CFN_addComma(value)}${intl.formatMessage({ id: 'unit.cupCount' })}`;
}

/**
 * @param {string} value
 * @returns
 */
export function userCount(value) {
  return `${CFN_addComma(value)}${intl.formatMessage({ id: 'unit.human' })}`;
}

export const CFNStoreStateString = (state) => {
  const lowStr = `${state}`.toLowerCase();

  if (lowStr === 'open') return intl.formatMessage({ id: 'const.string.operate.open' });
  if (lowStr === 'check') return intl.formatMessage({ id: 'const.string.operate.check' });
  if (lowStr === 'close') return intl.formatMessage({ id: 'const.string.operate.close' });
  if (lowStr === 'abnormality') return intl.formatMessage({ id: 'const.string.operate.abnormality' });

  return intl.formatMessage({ id: 'manage.users.status.unknown' });
};

/**
 * @param {string} list
 * @param {string} item
 * @param {stirng} format
 */
export const getDateLabels = (list, item, format) => {
  return list.map((el) => dayjs(el[item]).format(format)).filter((el) => el);
};

/**
 * V2 카드 레이아웃
 * @param {number} id
 * @param {string} title
 * @param {number} height
 * @param {*} content
 * @param {string} help
 * @param {string} etc
 * @param {*} breakpoints // [xs, sm, md, lg, xl]
 * @returns
 */
export const V2CardLayout = (id, title, height, content, help, etc, breakpoints) => {
  return {
    id: id,
    title: title,
    height: height,
    content: content,
    help: help,
    etc: etc,
    breakpoints: breakpoints,
  };
};

/**
 *
 * @param {string} day
 * @returns
 */
export const DayOfCommentsByTimezone = (day) =>
  day === 'WEEKDAY'
    ? intl.formatMessage({ id: 'const.string.weekday' })
    : intl.formatMessage({ id: 'const.string.weekend' });

/**
 *
 * @param {string} hour
 * @returns
 */
export const HourOfCommentsByTimezone = (hour) =>
  hour === 'DAY'
    ? `${intl.formatMessage({ id: 'cards.content.diurnal' })}(${intl.formatMessage({
        id: 'cards.content.weeklyTimeClassification',
      })})`
    : `${intl.formatMessage({ id: 'cards.content.nightTime' })}(${intl.formatMessage({
        id: 'cards.content.nightTimeClassification',
      })})`;

/**
 *
 * @param {number} num
 * @returns
 */
export const checkIncreaseOrDecrease = (num) => {
  if (num < 0) {
    return intl.formatMessage({ id: 'const.string.decrease' });
  } else {
    return intl.formatMessage({ id: 'const.string.increase' });
  }
};

/**
 * x축 날짜 노출 개수 계산하기
 * @param {number} diff
 * @returns
 */
export const getChartXaxisExposureNum = (diff) => {
  const scope = Math.ceil(diff / 6);
  return Math.floor(diff / scope);
};

/**
 * y축 최고값 계산하기
 * @param {num} num
 * @returns
 */
export const getChartYaxisMaxValue = (num) => {
  const floor = Math.floor(num);
  const mapfn = (arg) => Number(arg);
  const arr = Array.from(String(floor), mapfn);
  const power = Math.pow(10, Math.floor(Math.log10(floor)));
  const firstDigit = Math.floor(floor / power);
  const result = (num, idx, add) => (String(num)[idx] % 2 !== 0 ? num + add : num);

  if (floor < 10) {
    return 10;
  } else if (arr.length === 4) {
    const newNum = Math.ceil(num * 0.01) * 100;
    return result(newNum, 1, 100);
  } else if (arr.length === 5) {
    const newNum = Math.ceil(num * 0.001) * 1000;
    return result(newNum, 1, 1000);
  } else if (arr.length >= 6) {
    const newNum = Math.ceil(num * 0.0001) * 10000;
    const idx = arr.length - 5;
    return result(newNum, idx, 10000);
  } else {
    return (firstDigit + 1) * power;
  }
};

/**
 * 두 날짜 사이의 일수 구하기
 * @param {object} date
 * @returns
 */
export const CFN_dateDiff = (date) => {
  const start = dayjs(date.startDate);
  const end = dayjs(date.endDate);
  return end.diff(start, 'day');
};

/**
 *
 * @param {string} pathname
 * @param {string} link
 * @returns
 */
export const CFN_includesPath = (pathname, link) => {
  const findPosition = pathname.lastIndexOf('/');
  const pathFirst = pathname.substring(0, findPosition);
  const isinclude = pathFirst.length > 0 ? `${link}`.includes(pathFirst) : false;
  return isinclude;
};

/**
 * 시간 오전, 오후 표시
 * @param {number} time
 * @returns
 */
export const CFN_hour_h = (time) => {
  if (time >= 12) {
    return `${intl.formatMessage({ id: 'unit.pm' }, { hour: time === 12 ? 12 : time - 12 })}`;
  } else {
    return `${intl.formatMessage({ id: 'unit.am' }, { hour: time })}`;
  }
};

/**
 * 비율값을 기반으로 화살표와 색상을 생성한 후, 결과를 포함하는 HTML 요소를 반환
 * @param {number} value
 * @param {string} trend
 * @returns
 */

export const CFN_ratio = (value, notArrow) => {
  const arrow = notArrow ? '' : value > 0 ? '↑' : '↓';
  const color = notArrow ? theme.color.darkGray : value > 0 ? theme.color.red : theme.color.blue;
  const checkValue = !notArrow ? (value < 0 ? Math.abs(value) : value) : value;

  return (
    <>
      <span style={{ color: color, fontWeight: 'normal' }}>{`(${checkValue}%)${arrow}`}</span>
    </>
  );
};

/**
 * 다국어 메시지 포맷
 * @param {string} id
 * @param {object} obj
 * @returns
 */

export const CFN_intl = (id, ...obj) => {
  return obj ? intl.formatMessage({ id: id }, ...obj) : intl.formatMessage({ id: id });
};

/**
 * 데이터의 모든 요소가 0인지 확인하고 true면 0 값을 undefined로 바꾼다.
 * @param {string} data
 * @returns
 */
export const CFN_zerosToUndefined = (data) => {
  if (data.filter((num) => num === 0)) {
    return data.map((el) => (el === 0 ? undefined : el));
  } else {
    return data;
  }
};

export const CFN_updateColor = (value) => {
  const str = String(value);
  const checkValue = (includes) => str.length > 1 && str?.includes(includes);

  if (checkValue('+')) {
    return theme.color.blue;
  } else if (checkValue('-')) {
    return theme.color.red;
  } else {
    return theme.color.darkGray;
  }
};

export const CFN_operatingStatus = (status) => {
  const statusMap = {
    OPEN: 'cards.content.status.open',
    CHECK: 'cards.content.status.check',
    CLOSE: 'cards.content.status.close',
    CLOSED: 'cards.content.status.close',
    ABNORMALITY: 'cards.content.status.abnormality',
    WASHING: 'cards.content.status.washing',
  };
  return statusMap[status] ? CFN_intl(statusMap[status]) : CFN_intl('manage.users.role.unknown');
};

/**
 *
 * @param {number} num
 * @param {string} unit
 * @returns
 */
export const CFN_convertUnit = (num, unit = '') => {
  const value = Math.ceil((num / 1000) * 10) / 10;
  return unit ? `${value}${unit}` : value;
};

/**
 *
 * @param {string} role
 * @returns
 */
export const CFN_roleName = (role) => {
  const roleMap = {
    ADMIN: CFN_intl('manage.users.role.admin'),
    MANAGER: CFN_intl('manage.users.role.manager'),
  };

  return roleMap[role];
};

export const CFN_truncateText = (text, maxLength) => {
  if (!text) return null;
  if (text.length <= maxLength) {
    return <span>{text}</span>;
  } else {
    const truncatedText = text.substring(0, maxLength) + '...';
    return <span title={text}>{truncatedText}</span>;
  }
};

export const CFN_statusName = (status) => {
  const statusMap = {
    WAIT: CFN_intl('manage.users.status.wait'),
    ACTIVE: CFN_intl('manage.users.status.active'),
    STOP: CFN_intl('manage.users.status.stop'),
  };

  return statusMap[status];
};

/**
 * USP 등록 가능한 쿠폰 필터
 * @param {array} data
 * @param {string} date
 */
export const CFN_filterByUspAvailableCoupons = (data, date) => {
  const filtersCoupons = data.filter((item) => {
    const publishStartDate = new Date(item.publish_st_date);
    const publishEndDate = new Date(item.publish_ed_date);
    const targetDate = new Date(date);
    const dayOfWeek = dayjs(targetDate).day();
    const reserveDate = item.reserve_dates.some((item) => dayjs(item).isSame(date, 'day'));

    const daysArray = item.days_of_week.split(',').map((day) => {
      const num = Number(day) + 1;
      return num === 7 ? 0 : num;
    });

    return (
      !reserveDate &&
      daysArray.includes(dayOfWeek) &&
      !isDate1InThisWeek(targetDate) &&
      !isPast(targetDate) &&
      targetDate >= publishStartDate &&
      targetDate <= publishEndDate
    );
  });
  return filtersCoupons;
};

/**
 * 라인 그래프 날짜 노출 개수 계산하기
 * @param {array} arr
 */
export const calculateDateCountForLineChart = (arr) => {
  const count = Math.ceil(arr.length / 6);
  return Math.floor(arr.length / count);
};

/**
 * 배열을 n개의 부분 배열로 나누기
 * @param {array} arr
 * @param {number} num
 * @returns
 */
export const partitionArray = (arr, num, fix) => {
  const middle = arr.slice(1, arr.length - 1);

  const chunkedArray = [];
  const chunkSize = Math.ceil(middle.length / 5);
  let currentIndex = 0;

  for (let i = 0; i < num; i++) {
    const currentChunkSize = i < middle.length % num ? chunkSize - 1 : chunkSize;
    const chunk = middle.slice(currentIndex, currentIndex + currentChunkSize);
    chunkedArray.push(chunk);
    currentIndex += currentChunkSize;
  }

  const result = chunkedArray.map((subArray) => subArray[Math.floor(subArray.length / 2)]);
  result[0] = arr[0];

  if (fix) {
    result[result.length - 1] = fix;
    result.push(arr[arr.length - 1]);
  } else {
    result[result.length - 1] = fix = arr[arr.length - 1];
  }
  return result;
};

/*
 * 구독 서비스 체크하기
 */
export const CFN_isSubscription = (storeCode, storeList) => {
  if (isNull(storeCode)) return null;
  const subscription = storeList.find((el) => el.code === storeCode).subscription;
  const { product, is_experience } = subscription;
  const subscriptionService = product === 'PREMIUM' || is_experience;
  return subscriptionService;
};

/*
 * 구독 서비스 체험판 체크하기
 */
export const CFN_isTrialVersion = (storeCode, storeList) => {
  if (isNull(storeCode)) return null;
  const subscription = storeList.find((el) => el.code === storeCode).subscription;
  const { is_experience } = subscription;
  return is_experience;
};

/**
 * USP 쿠폰 회수율
 */

export const CFN_uspCouponRecoveryRate = (coupon) => {
  const rate = coupon.user_coupon_use_count / coupon.user_coupon_publish_count || 0;
  return `${CFN_TOFIXED(rate * 100, 1) || 0}%`;
};
